import * as React from "react";
import { Link, graphql } from "gatsby";

import Layout from "../../../components/layout";

const NewsCategory = ({ data }) => {
  const pageTitle = data.microcmsNewsCategory.name;
  return (
    <Layout pageTitle={pageTitle}>
      {data.allMicrocmsNews.nodes.map((node) => {
        const { title, newsId } = node;
        return (
          <article>
            <Link to={`/news/${newsId}`}>{title}</Link>
          </article>
        );
      })}
      <div>
        <hr />
        <Link to="/news/category">カテゴリ一覧</Link>
        <br />
        <Link to="/news">News一覧</Link>
      </div>
    </Layout>
  );
};

export default NewsCategory;

export const query = graphql`
  query ($slug: String) {
    microcmsNewsCategory(slug: { eq: $slug }) {
      slug
      name
    }
    allMicrocmsNews(filter: { category: { slug: { eq: $slug } } }) {
      nodes {
        title
        newsId
      }
    }
  }
`;
